import Vue from 'vue';
import VueRouter from 'vue-router';
import LandingPage from '../views/LandingPage.vue';
import RecommendedProcedures from '../views/RecommendedProcedures.vue';
import RecommendedProceduresDetail from '../views/RecommendedProceduresDetail.vue';
import Workplaces from '../views/Workplaces.vue';
import Literatures from '../views/Literatures.vue';
import Congresses from '../views/Congresses.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: LandingPage,
  },
  {
    path: '/doporucene-postupy',
    name: 'recommended-procedures',
    component: RecommendedProcedures,
  },
  {
    path: '/doporucene-postupy/:id',
    name: 'recommended-procedures-detail',
    component: RecommendedProceduresDetail,
  },
  {
    path: '/pracoviste',
    name: 'workplaces',
    component: Workplaces,
  },
  {
    path: '/literatura',
    name: 'literatures',
    component: Literatures,
  },
  {
    path: '/vzdělávací-akce',
    name: 'congresses',
    component: Congresses,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
