<template>
  <div id="congress-table">
    <div class="inner">
      <v-data-table
        :headers="congressHeaders"
        :items="computedActivities"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        hide-default-footer
        @click:row="redirectTo"
      >
        <template #item.starts_at="{ item }">
          {{ getDate(item) }}
        </template>
      </v-data-table>
      <div class="text-center">
        <div
          class="
            d-md-flex
            align-center
            justify-md-space-between
            base-pagination
          "
        >
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="7"
            circle
          ></v-pagination>
          <div class="d-flex align-center">
            <label class="mr-4 pb-2">Počet položek na stránku</label>
            <v-select
              class="pagination-select"
              :value="itemsPerPage"
              :items="pageOptions"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import staticActivities from "@/assets/activities.json";

export default {
  name: "CongressTable",
  data() {
    return {
      page: 1,
      totalActivitiesCount: 0,
      itemsPerPage: 10,
      pageOptions: [10, 50, 100],
      specialitiesIds: ["25", "49", "65", "64", "66", "80", "51"],
      activities: [],
      congressHeaders: [
        {
          text: "Název kurzu",
          value: "name",
          sortable: false,
        },
        {
          text: "Specializace",
          value: "activitySpeciality",
          sortable: false,
        },
        {
          text: "Kredity",
          value: "credits",
          sortable: false,
        },
        {
          text: "Místo konání",
          value: "location",
          sortable: false,
        },
        {
          text: "Datum konání",
          value: "starts_at",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalActivitiesCount / this.itemsPerPage);
    },
    computedActivities() {
      return this.activities.map((activity) => ({
        ...activity,
        activitySpeciality: activity.specialities
          .map((specialities) => specialities.name)
          .join(", "),
      }));
    },
    skipParams() {
      return this.page * this.itemsPerPage - this.itemsPerPage;
    },
    apiUrl() {
      return process.env.NODE_ENV === "development"
        ? ""
        : "https://www.vzdelavanilekaru.cz";
    },
  },
  watch: {
    itemsPerPage() {
      this.fetchActivities(this.skipParams);
    },
    page() {
      this.fetchActivities(this.skipParams);
    },
  },
  methods: {
    getDate(item) {
      let unformattedDate = new Date(item.starts_at);
      let date = unformattedDate.getDate();
      let month = unformattedDate.getMonth() + 1;
      let year = unformattedDate.getFullYear();
      return `${date}. ${month}. ${year}`;
    },
    redirectTo(value) {
      window.open(`https://www.vzdelavanilekaru.cz/aktivity/${value.id}`);
    },
    fetchActivities(skip) {
      const params = new URLSearchParams(
        this.specialitiesIds.map((i) => ["specialitiesIds", i])
      );
      params.append("take", this.itemsPerPage);
      params.append("skip", skip);
      params.append("includeOnlyNarrowlyFocused", "true");
      params.append("status", "uncompleted");
      params.append("orderBy", "DateAsc");

      const queryParams = params.toString();

      // Fetch metadata
      fetch(`${this.apiUrl}/api/v1/activities/metadata?${queryParams}`)
        .then((response) => response.json())
        .then((data) => (this.totalActivitiesCount = data.metadata.count))
        .catch(() => (this.totalActivitiesCount = staticActivities.length));

      fetch(`${this.apiUrl}/api/v1/activities?${queryParams}`)
        .then((response) => response.json())
        .then((data) => (this.activities = data.activities))
        .catch(() => (this.activities = staticActivities));
    },
  },
  mounted() {
    this.fetchActivities(0);
  },
};
</script>

<style lang="scss" scoped>
#congress-table {
  .v-data-table {
    box-shadow: 0 0 30px rgb(0 0 0 / 5%);
    border-radius: 1rem 1rem 0 0;
    ::v-deep .v-data-table__wrapper {
      border-radius: 1rem;
    }

    ::v-deep th {
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      background-color: #d5ecf9;
      white-space: nowrap;
    }

    ::v-deep tr {
      td {
        font-size: 1.1rem;
        padding: 1rem;
        font-weight: 400;
        cursor: pointer;
      }

      td:first-of-type {
        font-weight: 500;
      }

      td:last-of-type {
        white-space: nowrap;
      }
    }
  }

  .base-pagination {
    background: white;
    border-radius: 0 0 1rem 1rem;
    padding: 0 1rem;
    border-top: 1px solid gainsboro;
    ::v-deep .v-pagination {
      button {
        box-shadow: none;
      }

      .v-pagination__item--active {
        background-color: transparent !important;
        color: #40bced;
        font-weight: bold;
      }
    }
  }

  .pagination-select {
    max-width: 5rem;
    font-size: 1.2rem;
  }
}
</style>
