<template>
  <div id="literatures">
    <div class="inner">
      <h1>Literatura</h1>
      <div class="literatures-grid d-flex flex-wrap mb-12">
        <div
          v-for="literature in $store.state.literatures"
          :key="literature.name"
          class="literature-tile"
        >
          <div class="literature-name">
            {{ literature.name }}
          </div>
          <v-img
            width="600"
            height="450"
            position="top center"
            class="literature-img"
            :src="literature.thumbnail"
            @click="openLiterature(literature.googleCode, literature.url)"
          >
            <template v-slot:placeholder>
              <v-sheet height="100%">
                <v-skeleton-loader type="image"/>
              </v-sheet>
            </template>
          </v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Literatures",
  methods: {
    openLiterature(googleCode, url) {
      if (googleCode) {
        open(`https://drive.google.com/file/d/${googleCode}/view?usp=sharing`);
      } else {
        open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#literatures {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  .inner {
    padding-top: 8rem;
  }
  h1 {
    margin-bottom: 4rem;
  }
  .literature-tile {
    margin-right: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: box-shadow 0.4s;
    max-width: 100%;

    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .literature-name {
      background-color: whitesmoke;
      color: gray;
      padding: 1rem;
      border: 1px solid gainsboro;
    }
  }

  ::v-deep .v-skeleton-loader__image {
    height: 450px;
  }
}
</style>
