import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    procedures: [
      {
        name: "Diagnostika podvýživy, Grading ztráty hmotnosti, Role nutričního terapeuta, Sipping v onkologii, Enterální sondová výživa",
        romanNumber: "I",
        id: 1,
        brochureDocument: "1vLc0Drtq3Z7dnX4dz4qNpwyrJpDeU6ts",
        textDocument: "1-CXZQKawYRSaa71XIGBr_lcl9Soczb1g",
      },
      {
        name: "Parenterální výživa, Žilní vstupy, Perioperační nutriční podpora",
        romanNumber: "II",
        id: 2,
        brochureDocument: "1PN5TxijZlYLl5KLy0CcQoUkZfKSiCk8D",
        textDocument: "1n4u09OtgNhBxxut0GxVij7b6bK4X7bIk",
      },
      {
        name: "Paliativní chemoterapie, Paliativní symptomatická péče, Podpůrná léčba při malnutrici",
        romanNumber: "III",
        id: 3,
        brochureDocument: "1aA4FDbDSJBoHurfnBKFlMhzSv7MwiiBO",
        textDocument: "1aOcI5TP6vMyDwtiUXD9yEN-zFPI0RYIh",
      },
      {
        name: "Kolorektální karcinom, Karcinom pankreatu, Karcinom žaludku a žlučových cest, Nádory jícnu, Karcinom Žaludku",
        romanNumber: "IV",
        id: 4,
        brochureDocument: "1G8j5tIHwG7uxxGQCkjyc8YQELmesOo8D",
        textDocument: "1e4Ux5sIDMu56wyIlpV6n7unGJLpIhSGF",
      },
      {
        name: "Nádory hlavy a krku, Karcinom plic",
        romanNumber: "V",
        id: 5,
        brochureDocument: "1dEYYsnUO7PrVQvnNp48qGne22R4X93dr",
        textDocument: "1DtvOofEhUrvTOWayj0vwybCo1bmrqUMk",
      },
    ],
    literatures: [
      {
        name: "Výživa onkologického pacienta – přehled studií",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1XoauSyCqeaZQI0HYEcbgPvbIGNol9s2h",
        googleCode: "1344smmhqrDh-BQLQC_abS57EEniSm__w",
      },
      {
        name: "Dotazník hodnocení nutričního rizika",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1PcVJg_i8r5WMmOf7yB6nm3MUjeTe7do6",
        googleCode: "16E2gN7ELkbdp5IlDkMuEjtX6Nw0IETkr",
      },
      {
        name: "Nutriční doporučení",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1aN5SyiVZH-jDQSYk5KRi4cJRY-t9hFJi",
        googleCode: "19mJjlivRHfGO1ibj1EAjzLESFAScfG0u",
      },
      {
        name: "Tekutá enterální výživa k popíjení. Sipping",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1H5OLU1IQz95qF0VxzovJzTRfBz_L_mJY",
        googleCode: "1b61S7OKBNHEhWM9hHszZ8Cp0pW5KgZva",
      },
      {
        name: "Výživná dieta pro onkologického pacienta",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1PWQ9uncF1wK0thEHSyf6eDPhkDf4yshs",
        googleCode: "1Ps9R1-F6eYXpbxHv91f2xkrHVBMVn-EL",
      },
      {
        name: "Schema postupu řešení nutričních rizik",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1A_faTRFvX36gpJkmQZLHm5ccGLrww1VF",
        googleCode: "1aMZojeMGP51A_t3NbBsCECK9n7pow28T",
      },
      {
        name: "Modrá kniha ČOS",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1dO34qJ7q-t65GVWCfIuhx3eW1KqxfWWk",
        googleCode: null,
        url: "https://www.linkos.cz/lekar-a-multidisciplinarni-tym/personalizovana-onkologie/modra-kniha-cos/aktualni-vydani-modre-knihy/27-30-indikace-nutricni-podpory-onkologicky-nemocnych/",
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
