<template>
  <div id="congress-table-section">
    <div class="inner">
      <h2 class="text-center">Aktuální vzdělávací akce</h2>
      <congress-table />
    </div>
  </div>
</template>

<script>
import CongressTable from "./CongressTable.vue";
export default {
  components: { CongressTable },
};
</script>

<style lang="scss" scoped>
#congress-table-section {
  background-color: #fafafa;
  .inner {
      padding: 4rem 0 8rem 0;
  }
}
</style>