<template>
  <div id="recommended-procedures">
    <div class="inner">
      <h1>Doporučené postupy</h1>
      <div class="d-md-flex flex-wrap mt-12">
        <div
          class="procedure"
          v-for="procedure in $store.state.procedures"
          :key="procedure.name"
          @click="redirectToDetail(procedure.id)"
        >
          <v-img
            class="procedure__placeholder"
            :src="require(`../assets/img/Presentation${procedure.id}.png`)"
          >
            <template v-slot:placeholder>
              <v-sheet height="100%">
                <v-skeleton-loader type="image" />
              </v-sheet>
            </template>
          </v-img>

          <div class="procedure__name">
            <strong>Blok {{ procedure.romanNumber }}</strong
            >: {{ procedure.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToDetail(detailId) {
      this.$router.push({
        name: "recommended-procedures-detail",
        params: { id: detailId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#recommended-procedures {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .inner {
    padding: 8rem 1rem 1rem 1rem;
  }
  h1 {
    margin-bottom: 4rem;
  }
  .procedure {
    margin: 0 0 2rem 0;
    max-width: 100%;
    cursor: pointer;
    transition: box-shadow 0.4s;
    border: 1px solid gainsboro;
    background-color: whitesmoke;

    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: 1px solid gainsboro;

      .procedure__name {
        color: $primary;
      }
    }
    .procedure__placeholder {
      max-width: 100%;
      background-color: whitesmoke;
    }
    .procedure__name {
      font-size: 1.2rem;
      color: gray;
      transition: color 0.4s;
      background-color: whitesmoke;
      padding: 0.5rem;
    }
  }
}

@media screen and (min-width: 960px) {
  #recommended-procedures {
    .procedure {
      max-width: 30%;
      margin: 0 2rem 2rem 0;
    }

    .inner {
      padding: 8rem 0;
    }
  }
}
</style>
