<template>
  <div id="page-header">
    <div class="inner">
      <h1>
        Vše o onkologické nutrici <br />
        na jednom místě
      </h1>
      <a href="#congress-table">
        <v-btn depressed color="primary" class="mt-8"
          >Aktuální vzdělavací akce</v-btn
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#page-header {
  background-color: $secondary;
  padding: 12rem 0 8rem 0;
  background-image: url("../assets/img/footer_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .v-btn {
    font-size: 0.8rem;
    padding: 1.5rem 2rem;
  }
}

@media screen and (min-width: 600px) {
  #page-header {
    h1 {
      font-size: 4rem;
      line-height: 5rem;
    }

    .v-btn {
      font-size: 1rem;
      padding: 1.5rem 2rem;
    }
  }
}
</style>
